.tr-body {
  margin-left: 40px;
  margin-right: 40px;
}

.shd {
  display: flex;
  justify-content: center;
  font-weight: 900;
  font-size: larger;
  margin-top: 3em;
  color: #00235A;
  margin-bottom: 2em;
}

.shd-txt {
  border-bottom: 10px solid orangered;
}

/* .shipper {
  margin-right: 80%;
} */

.shp-txt {
  font-size: large;
  font-weight: 500;
  margin-bottom: 30px;
  border-bottom: 2px solid black;
}

.shipper-txt {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

.transit {
  background-color: green;
  color: white;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 2em;
}

.held {
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 2em;
}

.sst-div {
  width: 30em;
  margin-bottom: 2em;
  border: none;
  border-radius: 2em;
}

.shd-hd {
  border-bottom: 5px solid grey;
}

.col {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

@media screen and (max-width: 740px) {
  .shd {
    font-size: initial;
  }

  .shipper {
    margin-right: 0;
  }

  .shipper-txt, .col {
    grid-template-columns: initial;
  }

  .sst-div {
    width: initial;
  }
}